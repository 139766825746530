import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '..';
import { Loading, User } from '../../models';
import { authService } from '../../services';
import { removeAxiosToken, setAxiosToken } from '../../services/request';
import { AxiosError } from 'axios';

interface UserState {
  loading: Loading,
  userLogin: User | null,
  error: null,
  success: false, // for monitoring the registration process.
}

const name = 'user';

const initialState: UserState = {
  loading: 'normal',
  userLogin: null,
  error: null,
  success: false,
};

export const fetchUserLogin = createAsyncThunk(
  `${name}/login`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await authService.login(payload);
      console.log(response);
      // store user's token in local storage
      localStorage.setItem('access_token', response.token)
      setAxiosToken(response.token);
      return response;
    } catch (error: AxiosError | any) {
      console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const userLogout = createAsyncThunk(
  `${name}/logout`,
  async (payload: any, {fulfillWithValue, rejectWithValue }) => {
    try {
      // store user's token in local storage
      localStorage.removeItem('access_token')
      removeAxiosToken();
      console.log('Logout success');
      return fulfillWithValue({});
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLogin.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUserLogin.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userLogin = action?.payload?.user;
      })
      .addCase(fetchUserLogin.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.userLogin = null;
      })
  },
});

export default authSlice.reducer;
