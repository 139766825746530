import React, { ReactNode, useEffect, useState } from 'react';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Layout, theme, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { userLogout } from '../../../store/slices/auth.slices';

const { Header, Content, Sider, Footer } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const MENU_DATA = {
    // Sản phẩm
    DANH_SACH_SAN_PHAM: {sub_menu : "sub-san-pham", key : "danh-sach-san-pham", name : "Danh Sách Sản phẩm", path : "/"},
    THEM_SAN_PHAM : {sub_menu : "sub-san-pham", key : "them-san-pham", name : "Thêm Sản phẩm", path : "/them-san-pham"},
    CAP_NHAT_SAN_PHAM : {sub_menu : "sub-san-pham", key : "cap-nhat-san-pham", name : "Cập Nhật Sản phẩm", path : "/cap-nhat-san-pham/:id"},
    // Khách hàng
    DANH_SACH_KHACH_HANG: {sub_menu : "sub-khach-hang", key : "danh-sach-khach-hang", name : "Danh Sách Khách Hàng", path : "/danh-sach-khach-hang"},
    THEM_KHACH_HANG : {sub_menu : "sub-khach-hang", key : "them-khach-hang", name : "Thêm Khách Hàng", path : "/them-khach-hang"},
    CAP_NHAT_KHACH_HANG : {sub_menu : "sub-khach-hang", key : "cap-nhat-khach-hang", name : "Cập Nhật Khách Hàng", path : "/khach-hang/:id"},
    // Nhân viên
    DANH_SACH_NHAN_VIEN: {sub_menu : "sub-nhan-vien", key : "danh-sach-nhan-vien", name : "Danh Sách Nhân Viên", path : "/danh-sach-nhan-vien"},
    THEM_NHAN_VIEN : {sub_menu : "sub-nhan-vien", key : "them-nhan-vien", name : "Thêm Nhân Viên", path : "/them-nhan-vien"},
    CAP_NHAT_NHAN_VIEN : {sub_menu : "sub-nhan-vien", key : "cap-nhat-nhan-vien", name : "Cập Nhật Nhân Viên", path : "/nhan-vien/:id"},
     // Nhà cung cấp
     DANH_SACH_NHA_CUNG_CAP: {sub_menu : "sub-nha-cung-cap", key : "danh-sach-nha-cung-cap", name : "Danh Sách Nhà Cung Cấp", path : "/danh-sach-nha-cung-cap"},
     THEM_NHA_CUNG_CAP : {sub_menu : "sub-nha-cung-cap", key : "them-nha-cung-cap", name : "Thêm Nhà Cung Cấp", path : "/them-nha-cung-cap"},
     CAP_NHAT_NHA_CUNG_CAP : {sub_menu : "sub-nha-cung-cap", key : "cap-nhat-nha-cung-cap", name : "Cập Nhật Nhà Cung Cấp", path : "/nha-cung-cap/:id"},
    // User
    DANH_SACH_USER: {sub_menu : "sub-user", key : "danh-sach-user", name : "Danh Sách User", path : "/danh-sach-user"},
    THEM_USER : {sub_menu : "sub-user", key : "them-user", name : "Thêm User", path : "/them-user"},
    CAP_NHAT_USER : {sub_menu : "sub-user", key : "cap-nhat-user", name : "Cập Nhật User", path : "/user/:id"},
    // System
    LOGIN : {sub_menu : "", key : "login", name : "Đăng Nhập", path : "/login"},
    DANG_XUAT : {sub_menu : "", key : "dang-xuat", name : "Đăng Xuất", path : ""},
};

const items: MenuItem[] = [
    getItem('Sản Phẩm', MENU_DATA.DANH_SACH_SAN_PHAM.sub_menu, <HomeOutlined />, [
        getItem('Danh Sách', MENU_DATA.DANH_SACH_SAN_PHAM.key),
        getItem('Thêm Mới', MENU_DATA.THEM_SAN_PHAM.key)
    ]),

    getItem('Khách Hàng', MENU_DATA.DANH_SACH_KHACH_HANG.sub_menu, <AppstoreOutlined />, [
        getItem('Danh Sách', MENU_DATA.DANH_SACH_KHACH_HANG.key),
        getItem('Thêm Mới', MENU_DATA.THEM_KHACH_HANG.key)
    ]),

    getItem('Nhân Viên', MENU_DATA.DANH_SACH_NHAN_VIEN.sub_menu, <AppstoreOutlined />, [
        getItem('Danh Sách', MENU_DATA.DANH_SACH_NHAN_VIEN.key),
        getItem('Thêm Mới', MENU_DATA.THEM_NHAN_VIEN.key)
    ]),

    getItem('Nhà Cung Cấp', MENU_DATA.DANH_SACH_NHA_CUNG_CAP.sub_menu, <AppstoreOutlined />, [
        getItem('Danh Sách', MENU_DATA.DANH_SACH_NHA_CUNG_CAP.key),
        getItem('Thêm Mới', MENU_DATA.THEM_NHA_CUNG_CAP.key)
    ]),

    getItem('User', MENU_DATA.DANH_SACH_USER.sub_menu, <AppstoreOutlined />, [
        getItem('Danh Sách', MENU_DATA.DANH_SACH_USER.key),
        getItem('Thêm Mới', MENU_DATA.THEM_USER.key)
    ]),

    getItem('Đăng Xuất', MENU_DATA.DANG_XUAT.key, <LogoutOutlined />),
];

export const BasePage = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loading, menuSelect } = useAppSelector((state) => state.system);
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const onClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            // Sản phẩm
            case MENU_DATA.DANH_SACH_SAN_PHAM.key:
              navigate(MENU_DATA.DANH_SACH_SAN_PHAM.path);
              break;
            case MENU_DATA.THEM_SAN_PHAM.key:
              navigate(MENU_DATA.THEM_SAN_PHAM.path);
              break;
            // Khách hàng
            case MENU_DATA.DANH_SACH_KHACH_HANG.key:
            navigate(MENU_DATA.DANH_SACH_KHACH_HANG.path);
                break;
            case MENU_DATA.THEM_KHACH_HANG.key:
            navigate(MENU_DATA.THEM_KHACH_HANG.path);
                break;
            // Nhân viên
            case MENU_DATA.DANH_SACH_NHAN_VIEN.key:
            navigate(MENU_DATA.DANH_SACH_NHAN_VIEN.path);
                break;
            case MENU_DATA.THEM_NHAN_VIEN.key:
            navigate(MENU_DATA.THEM_NHAN_VIEN.path);
                break;
            // Nhà cung cấp
            case MENU_DATA.DANH_SACH_NHA_CUNG_CAP.key:
            navigate(MENU_DATA.DANH_SACH_NHA_CUNG_CAP.path);
                break;
            case MENU_DATA.THEM_NHA_CUNG_CAP.key:
            navigate(MENU_DATA.THEM_NHA_CUNG_CAP.path);    
                break;  
            // user
            case MENU_DATA.DANH_SACH_USER.key:
            navigate(MENU_DATA.DANH_SACH_USER.path);
                break;
            case MENU_DATA.THEM_USER.key:
            navigate(MENU_DATA.THEM_USER.path);    
                break;    
            case MENU_DATA.DANG_XUAT.key:
                dispatch(userLogout({}));
                navigate('/login', { replace: true });
                window.location.reload();
              break;
            default:
              console.log('Click menu');
          }
    };
    return (
        <Spin size="large" spinning={loading} style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <Layout className='layout'>
                <Sider className='layout-sider' width={250} style={{ background: '#001e3b' }} trigger={null} collapsed={collapsed} collapsible >
                    <div>
                        <Menu
                            selectedKeys={[menuSelect.key]}
                            mode="inline"
                            theme="dark"
                            onClick={onClick}
                            items={items}
                        />
                    </div>
                </Sider>
                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer,textAlign: 'initial' }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                            }}
                        />
                        <div className='title-app'>
                            <h1>{menuSelect.name}</h1>
                        </div>
                    </Header>
                    <Content className='overflow-x-hidden'
                        style={{
                            margin: '15px',
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
            <Footer className='footer'>CUONG MEDIA©</Footer>
        </Spin>
    );
}
