import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface SystemState {
  loading: false,
  menuSelect : {
    sub_menu : string,
    key : string,
    name : string,
    path : string
  }
}

const name = 'system';

const initialState: SystemState = {
  loading: false,
  menuSelect : {
    sub_menu : "",
    key : "",
    name : "",
    path : ""
  }
};

export const systemSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMenuSelect: (state, action) => {
      state.menuSelect = action.payload;
    }
  },
  extraReducers: (builder) => {},
});
export const { setLoading, setMenuSelect } = systemSlice.actions;
export default systemSlice.reducer;
