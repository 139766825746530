import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppRouteType } from '../models';
import { BasePage, MENU_DATA } from '../components/Layout/BasePage';
import Spinner from '../components/Common/Spinner';
import { setAxiosToken } from '../services/request';


const SanPhamListLazy = lazy(() => import('../pages/SanPham/SanPhamList'));
const UpdateSanPhamLazy = lazy(() => import('../pages/SanPham/UpdateSanPham'));
const AddSanPhamLazy = lazy(() => import('../pages/SanPham/AddSanPham'));
const KhachHangListLazy = lazy(() => import('../pages/KhachHang/KhachHangList'));
const AddKhachHangLazy = lazy(() => import('../pages/KhachHang/AddKhachHang'));
const UpdateKhachHangLazy = lazy(() => import('../pages/KhachHang/UpdateKhachHang'));
const NhanVienListLazy = lazy(() => import('../pages/NhanVien/NhanVienList'));
const AddNhanVienLazy = lazy(() => import('../pages/NhanVien/AddNhanVien'));
const UpdateNhanVienLazy = lazy(() => import('../pages/NhanVien/UpdateNhanVien'));
const NhaCungCapListLazy = lazy(() => import('../pages/NhaCungCap/NhaCungCapList'));
const AddNhaCungCapLazy = lazy(() => import('../pages/NhaCungCap/AddNhaCungCap'));
const UpdateNhaCungCapLazy = lazy(() => import('../pages/NhaCungCap/UpdateNhaCungCap'));
const UserListLazy = lazy(() => import('../pages/User/UserList'));
const AddUserLazy = lazy(() => import('../pages/User/AddUser'));
const UpdateUserLazy = lazy(() => import('../pages/User/UpdateUser'));
const NotFoundLazy = lazy(() => import('../pages/404'));
const LoginLazy = lazy(() => import('../components/Login'));

const waitFor = (Tag: React.LazyExoticComponent<() => JSX.Element | null>) => (
  <Suspense fallback={<Spinner />}>
    <Tag />
  </Suspense>
);

export const routes: AppRouteType[] = [
  // Sản phẩm
  {
    name: MENU_DATA.DANH_SACH_SAN_PHAM.name,
    auth: true,
    path: MENU_DATA.DANH_SACH_SAN_PHAM.path,
    component: SanPhamListLazy,
  },
  {
    name: MENU_DATA.THEM_SAN_PHAM.name,
    auth: true,
    path: MENU_DATA.THEM_SAN_PHAM.path,
    component: AddSanPhamLazy,
  },
  {
    name: MENU_DATA.CAP_NHAT_SAN_PHAM.name,
    auth: true,
    path: MENU_DATA.CAP_NHAT_SAN_PHAM.path,
    component: UpdateSanPhamLazy,
  },
  // Khách hàng
  {
    name: MENU_DATA.DANH_SACH_KHACH_HANG.name,
    auth: true,
    path: MENU_DATA.DANH_SACH_KHACH_HANG.path,
    component: KhachHangListLazy,
  },
  {
    name: MENU_DATA.THEM_KHACH_HANG.name,
    auth: true,
    path: MENU_DATA.THEM_KHACH_HANG.path,
    component: AddKhachHangLazy,
  },
  {
    name: MENU_DATA.CAP_NHAT_KHACH_HANG.name,
    auth: true,
    path: MENU_DATA.CAP_NHAT_KHACH_HANG.path,
    component: UpdateKhachHangLazy,
  },
  // Nhân viên
  {
    name: MENU_DATA.DANH_SACH_NHAN_VIEN.name,
    auth: true,
    path: MENU_DATA.DANH_SACH_NHAN_VIEN.path,
    component: NhanVienListLazy,
  },
  {
    name: MENU_DATA.THEM_NHAN_VIEN.name,
    auth: true,
    path: MENU_DATA.THEM_NHAN_VIEN.path,
    component: AddNhanVienLazy,
  },
  {
    name: MENU_DATA.CAP_NHAT_NHAN_VIEN.name,
    auth: true,
    path: MENU_DATA.CAP_NHAT_NHAN_VIEN.path,
    component: UpdateNhanVienLazy,
  },
  // Nhà cung cấp
  {
    name: MENU_DATA.DANH_SACH_NHA_CUNG_CAP.name,
    auth: true,
    path: MENU_DATA.DANH_SACH_NHA_CUNG_CAP.path,
    component: NhaCungCapListLazy,
  },
  {
    name: MENU_DATA.THEM_NHA_CUNG_CAP.name,
    auth: true,
    path: MENU_DATA.THEM_NHA_CUNG_CAP.path,
    component: AddNhaCungCapLazy,
  },
  {
    name: MENU_DATA.CAP_NHAT_NHA_CUNG_CAP.name,
    auth: true,
    path: MENU_DATA.CAP_NHAT_NHA_CUNG_CAP.path,
    component: UpdateNhaCungCapLazy,
  },
   // User
   {
    name: MENU_DATA.DANH_SACH_USER.name,
    auth: true,
    path: MENU_DATA.DANH_SACH_USER.path,
    component: UserListLazy,
  },
  {
    name: MENU_DATA.THEM_USER.name,
    auth: true,
    path: MENU_DATA.THEM_USER.path,
    component: AddUserLazy,
  },
  {
    name: MENU_DATA.CAP_NHAT_USER.name,
    auth: true,
    path: MENU_DATA.CAP_NHAT_USER.path,
    component: UpdateUserLazy,
  },
  // System
  {
    name: MENU_DATA.LOGIN.name,
    path: MENU_DATA.LOGIN.path,
    component: LoginLazy,
    isPublic: true,
  },
  {
    name: '404',
    path: '*',
    component: NotFoundLazy,
    isPublic: true,
  },
];

export default function RoutesAppRoutes() {
  const token = localStorage.getItem('access_token');
  if(token && token !== '') {
    setAxiosToken(token);
  }
  // const publicRoutes = routes
  //   .filter((route) => !route.auth || route.isPublic)
  //   .map((route) => (
  //     <Route
  //       key={route.path}
  //       path={route.path}
  //       element={waitFor(route.component)}
  //     />
  //   ));

  // public routes
  // if (!accessToken) return <Routes>{publicRoutes}</Routes>;

  // authenticated routes
  const authenticatedRoutes = routes
    // .filter(
    //   (route) =>
    //     (route.auth && hasPermission(route.roles, profile)) || route.isPublic
    // )
    .map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={waitFor(route.component)}
      />
    ));
  return (
    token && token !== '' ? (
        <BasePage>
          <Routes>{authenticatedRoutes}</Routes>
        </BasePage>
      )
      :
      (
        <Routes>{authenticatedRoutes}</Routes>
      )
  );
}
